/*
 * Copyright 2023 Tridium Inc. All rights reserved.
 */
import { useDispatch, useSelector } from 'react-redux';
import { customerSelector, customerActions } from './CustomerStore';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useCurrentUser } from '../utils/Authentication';

export function useCustomerStore() {
  const { loadedContext, loadedPages, nextPage, ...state } = useSelector(customerSelector);
  const dispatch = useDispatch();
  const currentUser = useCurrentUser();
  const ldc = useLDClient();
  const userTypeContext = currentUser?.organizationId?.toString() ?? '';
  useEffect(() => {
    if (currentUser && loadedContext !== userTypeContext) {
      dispatch(customerActions.setLoadedContext(userTypeContext));
      dispatch(customerActions.getCustomersAsync(currentUser ));
    }
  }, [currentUser, userTypeContext]);

  // useParams will return {} if it is not used within a router that routes a path containing the param /{customerId}
  // however that might have happened elsewhere in the app which would initialise the customer store.
  // If we don't get a customerId from useParams we fallback to what the customer store has.
  const currentCustomerId = useParams().customerId ?? state.currentCustomerId?.toString();
  const currentCustomer =
    state.customers.length === 1
      ? state.customers[0]
      : state.customers.find((c) => c.id.toString() === currentCustomerId);

  useEffect(() => {
    ldc?.identify({
      kind: 'user',
      key: currentUser.userId,
      customerId: currentCustomer?.id,
    });
  }, [ldc, currentCustomer?.id]);

  useEffect(() => {
    dispatch(customerActions.setCurrentCustomerId(currentCustomer?.id ?? null));
  }, [currentCustomer?.id]);

  return {
    ...state,
    currentCustomer,
    getCustomersAsync() {
      if (currentUser) dispatch(customerActions.getCustomersAsync(currentUser ));
    },
    getNextCustomerListAsync() {
      if (nextPage) dispatch(customerActions.getNextPage({ nextPageLink: nextPage.href }));
    },
    hasNextPage() {
      return nextPage !== undefined;
    },
  };
}
