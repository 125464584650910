/*
 * Copyright 2024 Tridium Inc. All rights reserved.
 */
import { niagaraHttp } from '../../utils/niagaraHttp';

const REQUEST_PROCESSING_PRIORITY = 255;

export type ReadResponse = {
  pointReadDetails: {
    cloudId: string;
    value: string;
    status: string;
  }[];
  systemGuid: string;
};

export type WriteResponse = {
  pointWriteDetails: {
    cloudId: string;
    status: string;
    writeTime: string;
  }[];
  systemGuid: string;
};
export type InputValues = {
   value: string;
   inputPriority: string;
   status: string;
   sourceId: string;
};
export type ReadAllResponse = {
  pointReadAllDetails: {
    cloudId: string;
    inputValues: InputValues[];
  }[];
  systemGuid: string;
};
export type CloudIdType = {
  cloudId: string;
  value: string;
  duration: number;
  inputPriority: number;
};

export const getPriority = (status: string) => status.split('@')[1].trim();

export const getStatus = (status: string) => status.split('@')[0].trim();;

export async function readAsync(systemGuid: string, cloudIds: string[]) {
  const path = `${window.ENV.API_BASE_URL}/api/v1/control/devices/${systemGuid}/commands/read`;
  return niagaraHttp.post<ReadResponse>(path, { cloudIds, requestProcessingPriority: REQUEST_PROCESSING_PRIORITY });
}

export async function readAllAsync(systemGuid: string, cloudIds: string[]) {
  const path = `${window.ENV.API_BASE_URL}/api/v1/control/devices/${systemGuid}/commands/readAll`;
  return niagaraHttp.post<ReadAllResponse>(path, { cloudIds, priority: REQUEST_PROCESSING_PRIORITY });
}

export async function writeAsync(systemGuid: string, points: CloudIdType[]) {
  const path = `${window.ENV.API_BASE_URL}/api/v1/control/devices/${systemGuid}/commands/write`;
  return niagaraHttp.post<WriteResponse>(path, {points, requestProcessingPriority: REQUEST_PROCESSING_PRIORITY });
}
